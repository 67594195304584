<template>
    <div class="RoleConfig">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px" v-if="hasPrivilege('biz.deptGroupRolePrivilege.edit')">
            <el-button :disabled="form.source === 'admin'" type="primary" @click="handleSave" size="small"
                >保存</el-button
            >
        </el-card>
        <BizPrivilegeConfig
            :checkPrivilegeFlag="editPrivilege"
            :bindCodes="bindCodes"
            :metaPrivileges="metaPrivileges"
            :prepared="prepared"
            @changeBindCodes="getBindCodes"
        />
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import BizPrivilegeConfig from '../component/BizPrivilegeConfig';

export default {
    name: 'RoleConfig',
    props: ['form'],
    components: { BizPrivilegeConfig },
    data() {
        return {
            url: {
                privileges: '/system/deptGroupRole/open/authority',
                bindPrivs: '/system/deptGroupRole/bindPrivs/',
            },
            bindCodes: [],
            backUp: [],
            metaPrivileges: {},
            prepared: false,
            editPrivilege: 'biz.rolePrivilege.edit',
        };
    },
    mounted() {
        const _this = this;
        UrlUtils.QueryRemote(this, this.url.privileges, (rst) => {
            _this.metaPrivileges = rst;
            _this.prepared = true;
        });
        UrlUtils.QueryRemote(this, this.url.privileges + '?roleCode=' + this.form.code, (rst) => {
            const _a = [];
            for (const i in rst) {
                _a.push(rst[i]);
            }
            _this.bindCodes = _a;
            _this.backUp = JSON.parse(JSON.stringify(_this.bindCodes));
        });
    },
    methods: {
        getBindCodes(newBindCodes) {
            this.bindCodes = newBindCodes;
        },
        notEditable(metaPrivilege) {
            //查不到直接锁定
            return this.metaPrivileges[metaPrivilege];
        },
        handleSave() {
            const _this = this;
            const checked = this.bindCodes.filter((a) => !this.backUp.includes(a));
            const unchecked = this.backUp.filter((a) => !this.bindCodes.includes(a));
            UrlUtils.PatchRemote(this, this.url.bindPrivs + this.form.code, { checked, unchecked }, null, () => {
                this.goBack();
                _this.$message.success('保存成功');
            });
        },
    },
};
</script>
